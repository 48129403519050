import request from "../utils/request";

/**
 * 确认开通会员
 * @param data
 * @returns {AxiosPromise}
 */
export const buyMember=(data)=>request({
    method:'POST',
    url:'/vip/buyVipByMoney',
    params:data
})

/**
 * 获取会员剩余天数
 */
export const getMemberRemainNum=()=>request({
    method:'get',
    url:'/vip/getUserVipTime',
})

/**
 * 会员激活码兑换会员
 */
export  const activeCodeMember=(activationCode)=>request({
    method:'Get',
    url:'/vip/buyVipByCode',
    params:{activationCode}
})

/**
 * 查询用户会员订单
 */
export  const getUserMemberOrder=()=>request({
    method:'get',
    url:'/vip/getVipOrderLast',
})

/**
 * 用户点击充值的时候生成个订单
 */
export  const addMemberOrder=(vipType)=>request({
    method:'get',
    url:'/vip/addVipOrder',
    params:{vipType}
})