<template>
  <div>
    <el-card shadow="hover">
      <div class="content">
        <div>{{$t('member.my_member')}}</div>
        <div class="info">
          <img v-if="userInfo.charge_style2==2 || userInfo.charge_style2==3" src="../../assets/img/member/svip_big.svg" alt="">
          <img v-if="userInfo.charge_style2==1" src="../../assets/img/member/vip_big.svg" alt="">
          <div>
            <div><span>{{username}}</span>
              <img style="vertical-align: middle" v-if="userInfo.charge_style2==3" src="../../assets/img/ssvip.svg" alt="">
              <img style="vertical-align: middle" v-else-if="userInfo.charge_style2==2" src="../../assets/img/member/svip.svg" alt="">
              <img style="vertical-align: middle" v-else-if="userInfo.charge_style2==1" src="../../assets/img/member/vip.svg" alt="">
              <img style="vertical-align: middle;margin-left: 5px" v-if="userInfo.charge_style2 == 2 || userInfo.charge_style2 == 3" src="../../assets/img/user/discount.svg">
            </div>
            <div><span style="opacity: 0.65">{{memberRemainNum}}</span>
              <span @click="$router.push('/grant_member?vipType=2')"  v-if="userInfo.charge_style2==2&&memberRemainInfo.duration_type!=2"  style="border: none">{{$t('member.renew_eight_discount')}}</span>
              <span @click="$router.push('/grant_member?vipType=2')"  v-if="userInfo.charge_style2==1&&memberRemainInfo.duration_type!=2"  style="border: none;">{{$t('member.upgrade')}}SVIP</span>
            </div>
          </div>
        </div>
        <div style="font-size: 14px;margin: 32px 0 24px 0  ;color: rgba(0, 0, 0, 0.85);">{{$t('member_right.enjoy')}}<span v-if="tabActive">9</span><span v-if="!tabActive">6</span>{{$t('member_right.big_rights')}}</div>
        <div class="icons">

          <div class="icon" v-if="tabActive">
            <img src="../../assets/img/member/rights/right_seven.svg" alt="">
            <div>
              <div>{{$t('member_right.content_title7_svip')}}</div>
              <div>{{$t('member_right.content_info7_s_svip')}}</div>
            </div>
          </div>

          <div class="icon" v-if="tabActive">
            <img src="../../assets/img/member/rights/right_one.svg" alt="">
            <div>
              <div>{{$t('member_right.content_title')}}</div>
              <div>{{$t('member_right.content_info')}}</div>
            </div>
          </div>

          <div class="icon">
            <img src="../../assets/img/member/rights/right_two.svg" alt="">
            <div>
              <div>{{$t('member_right.high_reach')}}<span v-if="userInfo.charge_style2==3">12+20</span><span v-else-if="userInfo.charge_style2==2">8+12</span><span v-else-if="userInfo.charge_style2==1">3+8</span>{{$t('member_right.content_title2')}}</div>
              <div>
                <span v-show="tabActive">SVIP{{$t('member_right.user_member1')}}<span v-if="userInfo.charge_style2==3">{{$t('member_right.user_member2')}}</span><span v-else-if="userInfo.charge_style2==2">{{$t('member_right.user_member3')}}</span><span v-else-if="userInfo.charge_style2==1">{{$t('member_right.user_member4')}}</span>，{{$t('member_right.user_member5')}}</span>
                <span v-show="!tabActive">VIP{{$t('member_right.user_member1')}}<span v-if="userInfo.charge_style2==3">{{$t('member_right.user_member2')}}</span><span v-else-if="userInfo.charge_style2==2">{{$t('member_right.user_member3')}}</span><span v-else-if="userInfo.charge_style2==1">{{$t('member_right.user_member4')}}</span>，{{$t('member_right.user_member6')}}</span>
              </div>
            </div>
          </div>

          <div class="icon" v-show="tabActive">
            <img src="../../assets/img/member/rights/right_three.svg" alt="">
            <div>
              <div>{{$t('member_right.content_title3')}}</div>
              <div>{{$t('member_right.content_info3_s')}}</div>
            </div>
          </div>

          <div class="icon" v-show="tabActive">
            <img src="../../assets/img/member/rights/right_four.svg" alt="">
            <div>
              <div>{{$t('member_right.content_title4')}}</div>
              <div>{{$t('member_right.content_info4_s')}}</div>
            </div>
          </div>

          <div class="icon">
            <img src="../../assets/img/member/rights/right_five.svg" alt="">
            <div>
              <div>{{$t('member_right.content_title5')}}</div>
              <div>{{$t('member_right.content_info5_s_min')}}<span v-show="tabActive">S</span>VIP{{$t('member_right.content_info5_s')}}</div>
            </div>
          </div>

          <div class="icon">
            <img src="../../assets/img/member/rights/right_six.svg" alt="">
            <div>
              <div>{{$t('member_right.content_title6')}}</div>
              <div>{{$t('member_right.content_info6_s')}}</div>
            </div>
          </div>

          <div class="icon" v-if="!tabActive">
            <img src="../../assets/img/member/rights/right_seven.svg" alt="">
            <div>
              <div>{{$t('member_right.content_title7')}}</div>
              <div>{{$t('member_right.content_info7_s')}}</div>
            </div>
          </div>

          <div class="icon">
            <img src="../../assets/img/member/rights/right_eight.svg" alt="">
            <div>
              <div>{{$t('member_right.content_title8')}}</div>
              <div>{{$t('member_right.content_info8_s')}}</div>
            </div>
          </div>

          <div class="icon">
            <img src="../../assets/img/member/rights/right_nine.svg" alt="">
            <div>
              <div>{{$t('member_right.content_title9')}}</div>
              <div>{{$t('member_right.content_info9_s')}}</div>
            </div>
          </div>
        </div>

      </div>
    </el-card>
  </div>
</template>

<script>
import {getUserInfo} from "../../server/rebate";
import {getMemberRemainNum} from "../../server/member";
export default {
  name: "Member",
  data(){
    return{
      username:'',
      tabActive:true,
      userInfo:{
        deposit:''
      },
      memberRemainNumInfo:'',
      //会员剩余信息
      memberRemainInfo:{
        duration_type:1
      },
    }
  },
  created() {
    this.getUserInfo()
    this.getMemberRemainNum()
  },
  computed:{
    memberRemainNum(){
      const memberInfo=this.memberRemainNumInfo
      if (memberInfo==null) return '2022.12.31 '+this.$t('member.expire')
      if (this.userInfo.charge_style2==3&&memberInfo.duration_type==2) return this.$t('member.perpetual_valid')
      if (this.userInfo.charge_style2==1&&memberInfo.duration_type==2) return this.$t('member.perpetual_valid')

      if (memberInfo.vip_type==0)   return  this.$t('member.not_yet_open')
      if (memberInfo.vip_type==2 || memberInfo.vip_type==3) {
        // const times=((new Date(memberInfo.svip_end_time).valueOf()-new Date().valueOf())/(1000*3600*24)).toFixed(0)
        const times=memberInfo.svip_end_time
        return `${(times.replace(/-/g,'.')).slice(0,times.indexOf(' '))}  `+this.$t('member.expire')
      }
      if (memberInfo.vip_type==1) {
        // const times=((new Date(res.data.vip_end_time).valueOf()-new Date().valueOf())/(1000*3600*24)).toFixed(0)
        const times=memberInfo.vip_end_time
        return `${(times.replace(/-/g,'.')).slice(0,times.indexOf(' '))} `+this.$t('member.expire')
      }
    }
  },
  methods:{
    async getUserInfo(){
      const {data: res}= await getUserInfo()
      this.userInfo=res
      if (res) {
        localStorage.setItem('userInfo', JSON.stringify(res))
      }
      const {name,mobile,charge_style2,username} = res
      name===null||name===''? this.username=mobile:this.username=name.length>6 ?name.slice(0,6)+'...' :name
      if ((mobile===null||mobile==='')&&(name===null||name==='')) this.username=username;
      (charge_style2==2|| charge_style2==3) ?this.tabActive=true :this.tabActive=false
    },
    //会员剩余天数
    getMemberRemainNum(){
      getMemberRemainNum().then(res=>{
        if (res.data!=null) this.memberRemainInfo=res.data
        this.memberRemainNumInfo=res.data
      })
    },
  }
}
</script>

<style scoped>
.content>.info{
  margin-top: 40px;
}
.content>.info>img{
  vertical-align: middle;
}
.content>.info>div{
  display: inline-block;
  vertical-align: middle;
  margin-left: 16px;
}
.content>.info>div>div:first-child>span{
  font-size: 18px;
  font-weight: 500;
  color: #1E2329;
  margin-right: 8px;
}
.content>.info>div>div:nth-child(2)>span:nth-child(1){
  font-size: 14px;
  font-weight: 400;
  color: #555452;
}
.content>.info>div>div:first-child{
  margin-bottom: 5px;
}
.content>.info>div>div:nth-child(2)>span:nth-child(2){
  font-size: 14px;
  font-weight: 400;
  color: #96754A;
  padding: 0 16px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  cursor: pointer;
}




.icons>div{
  display: inline-block;
  width: 30%;
  margin-bottom: 48px;
}
.icon>img{
  width: 18.7%;
  max-width: 68px;
  vertical-align: middle;
}
.icon>div{
  margin-left: 4.3%;
  display: inline-block;
  width: 77%;
  vertical-align: middle;
}
.icon>div>div:first-child{
  font-size: 16px;
  font-weight: 500;
  color: #1E2329;
  margin-bottom: 6px;
}
.icon>div>div:nth-child(2){
  font-size: 14px;
  font-weight: 400;
  color: #72777E;
}
.icon:nth-child(3n-1){
  margin: 0 5%;
}

@media screen and (max-width: 900px){
  .icon>img{
    width: 68px;
    vertical-align: middle;
  }
  .icons>div{
    display: flex ;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 40px;
  }
  .icon>div{
    margin-left: 16px;
    display: inline-block;
    min-width: 259px;
    vertical-align: middle;
  }
  .icon:nth-child(3n-1){
    margin: 0 0 40px 0;
  }
}

</style>
