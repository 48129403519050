<template>
  <div>
    <el-card class="lg" shadow="hover">
      <div>{{$t('personal.personal_data')}}</div>
      <div style="font-size: 14px;margin-top: 24px">{{$t('personal.avatar')}}</div>
      <div class="avatar">
        <div style="width: 142px;height: 142px;border-radius: 50%;border: solid 1px #D9D9D9">
          <img v-if="userInfo.user_face=='' || userInfo.user_face==null" style="width: 142px;height: 142px;border-radius: 50%;"  src="../../assets/img/avatar.png" alt="">
          <img v-else style="width: 142px;height: 142px;border-radius: 50%;"  :src="userInfo.user_face" alt="">
        </div>
        <el-upload
            class="upload-demo"
            action="#"
            :http-request="uploadSectionFile"
            name="user_face"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :on-success="handleUploadSuccess"
            :on-error="handleUploadError">
          <el-button style="margin-top: 10px" icon="el-icon-upload2" size="small" plain>{{$t('personal.click_upload')}}</el-button>
          <div style="width: 210px;" slot="tip" class="el-upload__tip">{{$t('personal.upload_tip')}}</div>
        </el-upload>
      </div>
      <div class="info">
        <div>
          <span>{{$t('personal.nickname')}} : </span><span v-if="!isEditName&&userInfo.name!==''&&userInfo.name!==null">{{userInfo.name}}</span><span v-if="!isEditName&&(userInfo.name==''||userInfo.name==null)">{{$t('personal.not_set')}}</span>
          <el-input v-if="isEditName" v-focus class="set-nick" v-model="info.name" @blur="handleChangeName" :placeholder="$t('personal.please_input')"></el-input>
          <img v-if="!isEditName" @click="isEditName=true;info.name=userInfo.name" src="../../assets/img/safe/alter_icon.svg" alt="">
        </div>
        <div>
          <span>{{$t('personal.passport')}} : </span><span v-if="!isEditPassport&&userInfo.certificates_no!==''&&userInfo.certificates_no!==null">{{userInfo.certificates_no}}</span><span v-if="!isEditPassport&&(userInfo.certificates_no==''||userInfo.certificates_no==null)">{{$t('personal.not_set')}}</span>
          <el-input v-if="isEditPassport" v-focus class="set-nick" v-model="info.passport" @blur="handleChangePassport" :placeholder="$t('personal.please_input')"></el-input>
          <img v-if="!isEditPassport" @click="isEditPassport=true;info.passport=userInfo.certificates_no" src="../../assets/img/safe/alter_icon.svg" alt="">
        </div>

        <div>
          <span>{{$t('personal.birth_date')}} : </span><span v-if="!isEditBirthDate&&userInfo.birthday!==''&&userInfo.birthday!==null">{{$util.formatDate_c(new Date(userInfo.birthday), '-')}}</span><span v-if="!isEditBirthDate&&(userInfo.birthday==''||userInfo.birthday==null)">{{$t('personal.not_set')}}</span>
          <el-date-picker
              v-if="isEditBirthDate"
              v-model="info.birthDate"
              v-focus
              :picker-options="pickerOptions"
              @blur="handleChangeBirthDate"
              type="date"
              :placeholder="$t('personal.select_date')">
          </el-date-picker>
          <img v-if="!isEditBirthDate" @click="isEditBirthDate=true;info.birthDate=userInfo.birthday" src="../../assets/img/safe/alter_icon.svg" alt="">
        </div>

        <div>
          <span>{{$t('personal.my_phone')}} : </span><span v-if="userInfo.mobile!=null&&userInfo.mobile!=''">{{userInfo.mobile}}</span><span v-else>{{$t('personal.not_bind_phone')}}</span>
          <span @click="handleBindPhone" style="font-size: 14px;font-weight: 400;color: #AB54DB;cursor: pointer"><span v-if="userInfo.mobile!=null && userInfo.mobile!=''">{{$t('personal.change')}}</span><span v-else>{{$t('personal.bind')}}</span></span>
        </div>
        <div>
          <span>{{$t('personal.my_email')}} : </span><span v-if="userInfo.username!=null&&userInfo.username!=''">{{userInfo.username}}</span><span v-else>{{$t('personal.not_bind_email')}}</span>
          <span @click="handleBindEmail" style="font-size: 14px;font-weight: 400;color: #AB54DB;cursor: pointer"><span v-if="userInfo.username!=null && userInfo.username!=''">{{$t('personal.change')}}</span><span v-else>{{$t('personal.bind')}}</span></span>
        </div>
      </div>
    </el-card>
  <div class="sm">
    <div class="container-item" style="background: #FFFFFF;margin-top: 12px;padding: 0 20px">
      <el-upload
          class="upload-sm"
          action="#"
          :http-request="uploadSectionFile"
          name="user_face"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :on-success="handleUploadSuccess"
          :on-error="handleUploadError">
        <div style="display: flex;justify-content: space-between;align-items: center;padding: 10px 0">
          <div>{{$t('personal.avatar')}}</div>
          <div  style="display: flex;align-items: center">
            <img v-if="userInfo.user_face!=null&&userInfo.user_face!=''" style="width: 58px;height: 58px;border-radius: 50%;" :src="userInfo.user_face" alt="">
            <img v-else style="width: 58px;height: 58px;border-radius: 50%;" src="../../assets/img/avatar.png" alt="">
            <i style="margin-left: 4px" class="el-icon-arrow-right"></i></div>
        </div>
      </el-upload>
      <div style="border-bottom: #EAECEF solid 1px;width: 100%;"></div>
      <div @click="alterNameDialogVisible=true;info.name=userInfo.name" style="display: flex;justify-content: space-between;align-items: center;padding: 16px 0">
        <div>{{$t('personal.nickname')}}</div>
        <div style="display: flex;align-items: center"><span>{{userInfo.name}}</span><i style="margin-left: 4px" class="el-icon-arrow-right"></i></div>
      </div>
      <div style="border-bottom: #EAECEF solid 1px;width: 100%;"></div>

      <div @click="alterPassportDialogVisible=true;info.passport=userInfo.certificates_no" style="display: flex;justify-content: space-between;align-items: center;padding: 16px 0">
        <div>{{$t('personal.passport')}}</div>
        <div style="display: flex;align-items: center"><span>{{userInfo.certificates_no}}</span><i style="margin-left: 4px" class="el-icon-arrow-right"></i></div>
      </div>
      <div style="border-bottom: #EAECEF solid 1px;width: 100%;"></div>

      <div @click="alterBirthDateDialogVisible=true" style="display: flex;justify-content: space-between;align-items: center;padding: 16px 0">
        <div>{{$t('personal.birth_date')}}</div>
        <div style="display: flex;align-items: center"><span v-if="!isEditBirthDate&&userInfo.birthday!==''&&userInfo.birthday!==null">{{$util.formatDate_c(new Date(userInfo.birthday), '-')}}</span><i style="margin-left: 4px" class="el-icon-arrow-right"></i></div>
      </div>
    </div>
    <div class="container-item" style="background: #FFFFFF;margin: 12px 0;padding: 0 20px">
      <div @click="handleBindPhone" style="display: flex;justify-content: space-between;align-items: center;padding: 16px 0">
        <div>{{$t('personal.my_phone')}}</div>
        <div style="display: flex;align-items: center"><span v-if="userInfo.mobile!=null&&userInfo.mobile!=''">{{userInfo.mobile}}</span><span v-else>{{$t('personal.not_bind_phone')}}</span><i style="margin-left: 4px" class="el-icon-arrow-right"></i></div>
      </div>
      <div style="border-bottom: #EAECEF solid 1px;width: 100%;"></div>
      <div @click="handleBindEmail" style="display: flex;justify-content: space-between;align-items: center;padding: 16px 0">
        <div>{{$t('personal.my_email')}}</div>
        <div style="display: flex;align-items: center"><span v-if="userInfo.username!=null&&userInfo.username!=''">{{userInfo.username}}</span><span v-else>{{$t('personal.not_bind_email')}}</span><i style="margin-left: 4px" class="el-icon-arrow-right"></i></div>
      </div>
    </div>
  </div>
<!--    修改暱稱對話框-->
    <el-dialog
        width="510px"
        :visible.sync="alterNameDialogVisible"
        :title="$t('personal.alter_nickname')"
        class="topUpDialog"
        height="304px">
      <el-input style="margin: 30px 0" v-model="info.name"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="alterNameDialogVisible = false">{{$t('personal.cancel')}}</el-button>
        <el-button type="primary" @click="handleChangeName('mobile')">{{$t('personal.confirm')}}</el-button>
      </span>
    </el-dialog>
    <!--    修改護照對話框-->
    <el-dialog
        width="510px"
        :visible.sync="alterPassportDialogVisible"
        :title="$t('personal.alter_passport')"
        class="topUpDialog"
        height="304px">
      <el-input style="margin: 30px 0" v-model="info.passport"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="alterPassportDialogVisible = false">{{$t('personal.cancel')}}</el-button>
        <el-button type="primary" @click="handleChangePassport('mobile')">{{$t('personal.confirm')}}</el-button>
      </span>
    </el-dialog>

    <van-popup v-model="alterBirthDateDialogVisible" position="bottom" round >
      <van-datetime-picker
          v-model="info.birthDate"
          type="date"
          :min-date="minDate"
          :max-date="maxDate"
          :title="$t('personal.select_y_t_d')"
          @confirm="handleChangeBirthDateMobile"
          @cancel="alterBirthDateDialogVisible=false"
      />
    </van-popup>


<!--    图片上传-->
    <el-dialog width="510px" :visible.sync="clipDialogVisible" append-to-body>
      <div class="cropper-content">
        <div class="cropper" style="text-align:center">
          <vueCropper
              ref="cropper"
              :img="option.img"
              :output-size="option.size"
              :output-type="option.outputType"
              :info="option.info"
              :full="option.full"
              :canScale="option.canScale"
              :can-move="option.canMove"
              :can-move-box="option.canMoveBox"
              :fixed="option.fixed"
              :fixed-box="option.fixedBox"
              :original="option.original"
              :auto-crop="option.autoCrop"
              :auto-crop-width="option.autoCropWidth"
              :auto-crop-height="option.autoCropHeight"
              :center-box="option.centerBox"
              :high="option.high"
              :info-true="option.infoTrue"
              :max-img-size="option.maxImageSize"
              :enlarge="option.enlarge"
              :mode="option.mode"
              :maxImgSize="option.maxImgSize"
          ></vueCropper>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clipDialogVisible = false">{{$t('personal.cancel')}}</el-button>
        <el-button type="primary" @click="finish">{{$t('personal.confirm')}}</el-button>
      </div>
    </el-dialog>
    <alter ref="alter"></alter>
    </div>
</template>

<script>
import {baseUrl} from "../../utils/request";
import {editUserAvatar, editUserBirth, editUserName, editUserPassport} from "../../server/user";
import {checkIsEmoji, checkIsEmojiGeneral} from "../../utils/validate";
import { VueCropper }  from 'vue-cropper'
import {blobToBase64, blobToFile} from "../../utils/tools";
import qs from "qs";
import alter from "./alter";
import {bindPhoneOrEmail} from "../../mixin/mixin";
export default {
  name: "Personal",
  data() {
    return {
      baseUrl,
      avatarAddress:'',
      token:localStorage.getItem('token'),
      userInfo:{},
      name:'',
      info:{
        name:'',
        passport:'',
        birthDate:''
      },
      pickerOptions:{
        disabledDate(time){
          return time.valueOf()>Date.now()
        }
      },
      isEditName:false,
      isEditPassport:false,
      isEditBirthDate:false,

    //  手機端
    //  修改暱稱對話框
      alterNameDialogVisible:false,
      alterPassportDialogVisible:false,
      alterBirthDateDialogVisible:false,
      minDate:'',
      maxDate:'',
    //  圖片上傳
      clipDialogVisible:false,
      option: {
        img: '', // 裁剪图片的地址
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'png', // 裁剪生成图片的格式
        full: true, // 是否输出原图比例的截图
        info: true, // 图片大小信息
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 300, // 默认生成截图框宽度
        autoCropHeight: 300, // 默认生成截图框高度
        canMove: true, // 上传图片是否可以移动
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        height: true,
        infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        enlarge: 1, // 图片根据截图框输出比例倍数
        // mode: 'container', // 图片默认渲染方式
        // maxImgSize: 375 // 限制图片最大宽度和高度
      },
      uploadFile:''
    };
  },
  components:{
    VueCropper,
    alter
  },
  mixins:[bindPhoneOrEmail],
  created() {
    this.getUserInfo()
    this.minDate=new Date(1921, 0, 1)
    this.maxDate=new Date()
    this.info.birthDate=new Date()
  },
  methods:{
    getUserInfo(){
      this.userInfo=JSON.parse(localStorage.getItem('userInfo'))
    },
    async handleChangeName(e){
      if (e!='mobile') this.isEditName=false
      if (e=='mobile'&&this.info.name=='') return this.$message.warning(this.$t('personal.please_input_nickname'))
      if (this.info.name==this.userInfo.name) return this.alterNameDialogVisible=false
      if (e!='mobile'&&(this.info.name==''||this.info.name==this.userInfo.name)) return
      if (!checkIsEmojiGeneral(this.info.name)) return this.$message.warning(this.$t('personal.nonsupport'))
      const res= await editUserName(this.info.name)
      if (!res.success) return this.$message.error(res.message)
      this.$message.success(this.$t('personal.alter_success'))
      this.userInfo.name=this.info.name
      localStorage.setItem('userInfo',JSON.stringify(this.userInfo))
      this.$emit('updateInfo')
      if (e=='mobile') this.alterNameDialogVisible=false
    },
    //修改護照
    async handleChangePassport(e){
      if (e!='mobile') this.isEditPassport=false
      if (e=='mobile'&&this.info.passport=='') return this.$message.warning(this.$t('personal.please_input_passport'))
      if (this.info.passport==this.userInfo.certificates_no) return this.alterPassportDialogVisible=false

      if (e!='mobile'&&(this.info.passport==''||this.info.passport==this.userInfo.certificates_no)) return
      const res= await editUserPassport(this.info.passport)
      if (!res.success) return this.$message.error(res.message)
      this.$message.success(this.$t('personal.alter_success'))
      this.userInfo.certificates_no=this.info.passport
      localStorage.setItem('userInfo',JSON.stringify(this.userInfo))
      if (e=='mobile') this.alterPassportDialogVisible=false
    },
    //修改生日
    async handleChangeBirthDate(e){
      this.isEditBirthDate=false
      if (this.info.birthDate==''||this.info.birthDate==this.userInfo.birthday||e.displayValue==undefined||e.displayValue=='') return
      const res= await editUserBirth(e.displayValue)
      if (!res.success) return this.$message.error(res.message)
      this.$message.success(this.$t('personal.alter_success'))
      this.userInfo.birthday=this.info.birthDate
      localStorage.setItem('userInfo',JSON.stringify(this.userInfo))
    },
    //修改生日的手機端
    async handleChangeBirthDateMobile(e){
      if (this.info.birthDate==this.userInfo.birthday) return
      const res= await editUserBirth(e)
      if (!res.success) return this.$message.error(res.message)
      this.$message.success(this.$t('personal.alter_success'))
      this.userInfo.birthday=this.info.birthDate
      localStorage.setItem('userInfo',JSON.stringify(this.userInfo))
      this.alterBirthDateDialogVisible=false
    },
    beforeAvatarUpload(file){
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt12M = file.size / 1024 / 1024 < 12;
      if (!isJPG && !isPNG) {
        this.$message.error(this.$t('personal.img_err1'));
      }
      if (!isLt12M) {
        this.$message.error(this.$t('personal.img_err2'));
      }
      if ((isPNG || isJPG) && isLt12M) {
        this.uploadFile=file
        return (isPNG || isJPG) && isLt12M;
      }

    },
    handleUploadSuccess(res,file){
      this.$nextTick(() => {
        this.option.img =window.URL.createObjectURL(this.uploadFile);
        this.clipDialogVisible = true
      })
    },
    finish() {
      this.$refs.cropper.getCropBlob(async data => {
        let formData = new FormData()
        formData.append('user_face',data,new Date().valueOf()+this.uploadFile.name);
        const res=await editUserAvatar(formData)
        if (!res.success) return this.$message.error(res.message)
        this.$message.success(this.$t('personal.avatar_upload_success'))
        this.userInfo.user_face=res.data
        localStorage.setItem('userInfo',JSON.stringify(this.userInfo))
        this.clipDialogVisible=false
      })
    },
    uploadSectionFile(){
      this.handleUploadSuccess()
    },
    handleUploadError(){},
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.querySelector('input').focus()
      }
    }
  }
}
</script>

<style scoped>
.avatar{
  text-align: center;
  width: 144px;
}
.info{
  font-size: 14px;
  font-weight: 400;
  color: #1E2329;
  line-height: 16px;
  margin-top: 48px;
  padding-bottom: 64px;
}
.info>div{
  height: 30px;
  margin-bottom: 15px;
  /*vertical-align: middle;*/
  display: flex;
  align-items: center;
}
.info>div>span{
  margin-right: 6px;
}
.info>div>img{
  margin-left: 7px;
  cursor: pointer;
}


.container-item>div>div:first-child{
  color: #262626;
  font-size: 16px;
}
.container-item>div>div:nth-child(2){
  color: #72777E;
}


.set-nick {
  margin: 0px !important;
  width: 100px;
  vertical-align: middle !important;
}

.set-nick >>> .el-input__inner {
  height: 30px;
  padding: 5px 0px;
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  border: none;
}
.set-nick >>>.el-input{
  width: 100px;
}
.sm{
  display: none;
}
@media screen and (max-width: 900px){
  .sm{display: block}
  .lg{display: none}
}

.cropper-content{
  width:100%;
  height:370px;
  background: pink;
}
.cropper {
  width: 100%;
  height: 370px;
  background: yellow;
}

 >>>.upload-sm>div{
  width: 100%;
}
</style>
