<template>
  <div class="container">
    <router-view @updateInfo="updateInfo"></router-view>
<!--    <Safe v-show="$store.state.sidebarType=='safe'"></Safe>-->
<!--    <Personal v-show="$store.state.sidebarType=='personal'"></Personal>-->
<!--    <Property v-show="$store.state.sidebarType=='property'"></Property>-->
<!--    <Member v-show="$store.state.sidebarType=='member'"></Member>-->
  </div>
</template>

<script>
import Member from "./Member";
import Safe from "./Safe";
import Property from "./Property";
import Personal from "./Personal";
import {mapGetters} from "vuex";
export default {
  name: "User",
  components:{
    Member,
    Safe,
    Property,
    Personal
  },
  methods:{
    updateInfo(){
      this.$emit('updateInfo')
    }
  },

  computed:{
    ...mapGetters([
      'getSidebarType'
    ])
  },
  watch:{
    getSidebarType(newValue){
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 900px){
  .container{
    margin-top: 12px;
    min-height: 450px;
  }

}

</style>
