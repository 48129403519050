<template>
<div>
  <el-dialog
      append-to-body
      :show-close="false"
      width="280px"
      class="tipDialog"
      :visible.sync="ClickTipDialogVisible">
    <span style="padding: 30px 20px;display: block;line-height: 24px">{{ content }}</span>
    <el-button style="width: 100%"  @click="ClickTipDialogVisible = false">确 定</el-button>
  </el-dialog>
</div>
</template>

<script>
export default {
  name: "dialog_tip",
  data(){
    return{
      ClickTipDialogVisible:false
    }
  },
  props:['content'],
}
</script>

<style scoped>
>>> .el-dialog__header{
  padding: 0;
}
>>>.el-dialog__body{
  padding: 0 !important;
}
.tipDialog{
  border-radius: 4px;
  text-align: center;
}
</style>