export const screenSize=(value)=>{
        window.addEventListener('resize',()=>{
            const bodyWidth=document.body.clientWidth
            return  bodyWidth>=value
        })
        const bodyWidth=document.body.clientWidth
        return  bodyWidth>=value
}

/**
 * blob格式转二进制base64
 */
export const blobToBase64 = (blob, callback) => {
    let r = new FileReader();
    r.onload = function (e) {
            callback(e.target.result);
    }
    r.readAsDataURL(blob);
}

/**
 * blob轉file
 */
export const blobToFile = (blob, fileName) => {
    const file = new File([blob], fileName, {type: blob.type});
    return file;
}

export const roundTwoFilter = value => {
    if (value=='' || value==null) return ''
    return Math.floor(value*100)/100
}
