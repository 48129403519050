<template>
  <div>
    <!--    更换手机的对话框-->
    <el-dialog
        :title="userBoundPhone ? $t('alter.change_login_phone'): $t('alter.bind_login_phone')"
        :visible.sync="changePhoneDialogVisible"
        :close-on-click-modal="false"
        @close="handleClosePhoneDialog"
        :destroy-on-close="true"
        width="510px">
      <el-form  :model="changePhoneForm" :rules="changePhoneFormRules" ref="changePhoneFormRef">
        <!--        当用户已经绑定过手机号-->
        <div v-if="userBoundPhone">
          <el-form-item prop="oldPhone">
            <div>{{ $t('alter.your_phone') }}</div>
            <el-input disabled v-model="changePhoneForm.oldPhone"></el-input>
          </el-form-item>
          <el-form-item prop="old_auth_code">
            <div>{{ $t('alter.old_phone_code') }}</div>
            <el-input v-model="changePhoneForm.old_auth_code"></el-input>
            <div class="auth-code">
              <div v-if="isOldPhoneSent" @click="sendAuthCode('oldPhone')" style="font-size: 14px;font-weight: 400;color: #AB54DB;">{{ $t('alter.send_code') }}</div>
              <div v-else><span>{{oldPhoneSeconds}}</span><span>{{ $t('robot.resend_code') }}</span></div>
            </div>
          </el-form-item>
          <el-form-item prop="newPhone">
            <div>{{ $t('alter.new_phone') }}</div>
            <el-input v-model="changePhoneForm.newPhone"></el-input>
          </el-form-item>
          <el-form-item prop="auth_code">
            <div>{{ $t('alter.new_phone_code') }}</div>
            <el-input v-model="changePhoneForm.auth_code"></el-input>
            <div class="auth-code">
              <div v-if="isPhoneSent" @click="sendAuthCode('phone')" style="font-size: 14px;font-weight: 400;color: #AB54DB;">{{ $t('alter.send_code') }}</div>
              <div v-else><span>{{phoneSeconds}}</span><span>{{ $t('alter.resend_code') }}</span></div>
            </div>
          </el-form-item>
        </div>
        <!--        当用户首次绑定手机号-->
        <div v-else>
          <el-form-item prop="newPhone">
            <div>{{ $t('alter.your_phone') }}</div>
            <el-input v-model="changePhoneForm.newPhone"></el-input>
          </el-form-item>
          <el-form-item prop="auth_code">
            <div>{{ $t('alter.phone_code') }}</div>
            <el-input v-model="changePhoneForm.auth_code"></el-input>
            <div class="auth-code">
              <div v-if="isPhoneSent" @click="sendAuthCode('phone')" style="font-size: 14px;font-weight: 400;color: #AB54DB;">{{ $t('alter.send_code') }}</div>
              <div v-else><span>{{phoneSeconds}}</span><span>{{ $t('alter.resend_code') }}</span></div>
            </div>
          </el-form-item>
        </div>

      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="changePhoneDialogVisible = false">{{ $t('alter.cancel') }}</el-button>
    <el-button type="primary" @click="handleChangePhone">{{ $t('alter.sure') }}</el-button>
  </span>
    </el-dialog>
    <!--    绑定邮箱的对话框-->
    <el-dialog
        :title="userBoundEmail ?$t('alter.change_email'):$t('alter.bind_email')"
        :visible.sync="bindEmailDialogVisible"
        :close-on-click-modal="false"
        @close="handleCloseEmailDialog"
        width="510px">
      <el-form  :model="bindEmailForm" :rules="bindEmailFormRules" ref="bindEmailFormRef">
        <!--        当用户已经绑定过邮箱需要修改邮箱的时候-->
        <div v-if="userBoundEmail">
          <el-form-item prop="oldEmail">
            <div>{{ $t('alter.email_address') }}</div>
            <el-input disabled v-model="bindEmailForm.oldEmail"></el-input>
          </el-form-item>
          <el-form-item prop="old_auth_code">
            <div>{{ $t('alter.old_email_code') }}</div>
            <el-input v-model="bindEmailForm.old_auth_code"></el-input>
            <div class="auth-code">
              <div v-if="isOldEmailSent" @click="sendAuthCode('oldEmail')" style="font-size: 14px;font-weight: 400;color: #AB54DB;">{{ $t('alter.send_code') }}</div>
              <div v-else><span>{{oldEmailSeconds}}</span><span>{{ $t('alter.resend_code') }}</span></div>
            </div>
          </el-form-item>
          <el-form-item prop="email">
            <div>{{ $t('alter.new_email_address') }}</div>
            <el-input v-model="bindEmailForm.email"></el-input>
          </el-form-item>
          <el-form-item prop="auth_code">
            <div>{{ $t('alter.new_email_code') }}</div>
            <el-input v-model="bindEmailForm.auth_code"></el-input>
            <div class="auth-code">
              <div v-if="isEmailSent" @click="sendAuthCode('email')" style="font-size: 14px;font-weight: 400;color: #AB54DB;">{{ $t('alter.send_code') }}</div>
              <div v-else><span>{{emailSeconds}}</span><span>{{ $t('alter.resend_code') }}</span></div>
            </div>
          </el-form-item>
        </div>
        <!--        当用户第一次绑定邮箱-->
        <div v-else>
          <el-form-item prop="email">
            <div>{{ $t('alter.email_address') }}</div>
            <el-input v-model="bindEmailForm.email"></el-input>
          </el-form-item>
          <el-form-item prop="auth_code">
            <div>{{ $t('alter.email_code') }}</div>
            <el-input v-model="bindEmailForm.auth_code"></el-input>
            <div class="auth-code">
              <div v-if="isEmailSent" @click="sendAuthCode('email')" style="font-size: 14px;font-weight: 400;color: #AB54DB;">{{ $t('alter.send_code') }}</div>
              <div v-else><span>{{emailSeconds}}</span><span>{{ $t('alter.resend_code') }}</span></div>
            </div>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="bindEmailDialogVisible = false">{{ $t('alter.cancel') }}</el-button>
    <el-button type="primary" @click="handleChangeEmail">{{ $t('alter.sure') }}</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  checkEmail, checkEmailGeneral,
  checkEmailOrIsExist,
  checkMobile,
  checkMobileGeneral,
  checkMobileOrIsExist
} from "../../utils/validate";
import {bindPhoneOrEmail, getAuthCode} from "../../server/auth";
import {getUserInfo} from "../../server/rebate";

export default {
  name: "alter",
  data(){
    return{
      userBoundEmail:false,
      userBoundPhone:false,
      changePhoneDialogVisible:false,
      changePhoneForm:{
        oldPhone:'',
        old_auth_code:'',
        newPhone:'',
        auth_code:''
      },
      changePhoneFormRules:{
        newPhone:[{ required: true, message: this.$t('alter.input_new_phone'), trigger: 'blur' },
          {validator:checkMobileOrIsExist,trigger: 'blur'}],
        auth_code:[{ required: true, message: this.$t('alter.input_code'), trigger: 'blur' }],
        oldPhone:[{ required: true, message: this.$t('alter.input_old_phone'), trigger: 'blur' },
          {validator:checkMobile,trigger: 'blur'}],
        old_auth_code:[{ required: true, message: this.$t('alter.input_code'), trigger: 'blur' }],
      },
      //绑定邮箱
      bindEmailDialogVisible:false,
      bindEmailForm:{
        oldEmail:'',
        old_auth_code:'',
        email:'',
        auth_code:''
      },
      bindEmailFormRules:{
        oldEmail:[{ required: true, message: this.$t('alter.input_old_email_address'), trigger: 'blur' },
          {validator:checkEmail,trigger: 'blur'}],
        old_auth_code:[{ required: true, message: this.$t('alter.input_old_email_code'), trigger: 'blur' }],
        email:[{ required: true, message: this.$t('alter.input_new_email_address'), trigger: 'blur' },
          {validator:checkEmailOrIsExist,trigger: 'blur'}],
        auth_code:[{ required: true, message: this.$t('alter.input_new_email_code'), trigger: 'blur' }],
      },

      isPhoneSent:true,
      phoneSeconds:60,
      isEmailSent:true,
      emailSeconds:60,
      isOldPhoneSent:true,
      oldPhoneSeconds:60,
      isOldEmailSent:true,
      oldEmailSeconds:60,
      isPhoneApiSent:true,
      phoneApiSeconds:60,
    }
  },
  created() {
    this.getUserInfo()
  },
  methods:{
    getUserInfo(){
      this.userInfo=JSON.parse(localStorage.getItem('userInfo'))
      this.userInfo.mobile!==''&&this.userInfo.mobile!==null?this.userBoundPhone=true:this.userBoundPhone=false
      this.userInfo.username!==''&&this.userInfo.username!==null?this.userBoundEmail=true:this.userBoundEmail=false
      this.changePhoneForm.oldPhone=this.userInfo.mobile
      this.bindEmailForm.oldEmail=this.userInfo.username
    },
    async sendAuthCode(kind){
      if (kind==='phone'){
        if (!checkMobileGeneral(this.changePhoneForm.newPhone)) return this.$message.error(this.$t('alter.sure_phone_format'))
        this.isPhoneSent=false
        const timer=setInterval(()=>{
          this.phoneSeconds--
          if (this.phoneSeconds===0){
            this.phoneSeconds=60
            this.isPhoneSent=true
            clearInterval(timer)
          }
        },1000)
        const res=await getAuthCode(3,2,this.changePhoneForm.newPhone)
        if (!res.success) return this.$message.warning(res.message)
        if (res.success) this.$message.success(this.$t('alter.code_sended_phone'))
      }
      else if (kind==='email'){
        if (!checkEmailGeneral(this.bindEmailForm.email)) return this.$message.error(this.$t('alter.sure_new_email_format'))
        this.isEmailSent=false
        const timer=setInterval(()=>{
          this.emailSeconds--
          if (this.emailSeconds===0){
            this.emailSeconds=60
            this.isEmailSent=true
            clearInterval(timer)
          }
        },1000)
        const res=await getAuthCode(3,1,this.bindEmailForm.email)
        if (!res.success) return this.$message.warning(res.message)
        if (res.success) this.$message.success(this.$t('alter.code_sended_email'))
      }
      if (kind==='oldPhone'){
        if (!checkMobileGeneral(this.changePhoneForm.oldPhone)) return this.$message.error(this.$t('alter.sure_phone_format'))
        this.isOldPhoneSent=false
        const timer=setInterval(()=>{
          this.oldPhoneSeconds--
          if (this.oldPhoneSeconds===0){
            this.oldPhoneSeconds=60
            this.isOldPhoneSent=true
            clearInterval(timer)
          }
        },1000)
        const res=await getAuthCode(3,2,this.changePhoneForm.oldPhone)
        if (!res.success) return this.$message.warning(res.message)
        if (res.success) this.$message.success(this.$t('alter.code_sended_phone'))
      }
      if (kind==='oldEmail'){
        if (!checkEmailGeneral(this.bindEmailForm.oldEmail)) return this.$message.error(this.$t('alter.sure_old_email_format'))
        this.isOldEmailSent=false
        const timer=setInterval(()=>{
          this.oldEmailSeconds--
          if (this.oldEmailSeconds===0){
            this.oldEmailSeconds=60
            this.isOldEmailSent=true
            clearInterval(timer)
          }
        },1000)
        const res=await getAuthCode(3,1,this.bindEmailForm.oldEmail)
        if (!res.success) return this.$message.warning(res.message)
        if (res.success) this.$message.success(this.$t('alter.code_sended_email'))
      }
      if (kind==='phoneApi'){
        if (!this.userBoundEmail) return this.$message.warning(this.$t('alter.bind_email_first'))
        this.isPhoneApiSent=false
        const timer=setInterval(()=>{
          this.phoneApiSeconds--
          if (this.phoneApiSeconds===0){
            this.phoneApiSeconds=60
            this.isPhoneApiSent=true
            clearInterval(timer)
          }
        },1000)
        const res=await getAuthCode(3,1,this.userInfo.username)
        if (!res.success) return this.$message.warning(res.message)
        if (res.success) this.$message.success(this.$t('alter.code_sended_email'))
      }
      if (kind==='unPhone'){
        this.isUnPhoneSent=false
        const timer=setInterval(()=>{
          this.unPhoneSeconds--
          if (this.unPhoneSeconds===0){
            this.unPhoneSeconds=60
            this.isUnPhoneSent=true
            clearInterval(timer)
          }
        },1000)
        const res=await getAuthCode(3,2,this.userInfo.mobile)
        if (!res.success) return this.$message.warning(res.message)
        if (res.success) this.$message.success(this.$t('alter.code_sended_phone'))
      }
      if (kind==='unEmail'){
        this.isUnEmailSent=false
        const timer=setInterval(()=>{
          this.unEmailSeconds--
          if (this.unEmailSeconds===0){
            this.unEmailSeconds=60
            this.isUnEmailSent=true
            clearInterval(timer)
          }
        },1000)
        const res=await getAuthCode(3,1,this.userInfo.username)
        if (!res.success) return this.$message.warning(res.message)
        if (res.success) this.$message.success(this.$t('alter.code_sended_email'))
      }
    },
    //  修改手机号
    handleChangePhone(){
      this.$refs.changePhoneFormRef.validate( async valid=>{
        if (!valid) return this.$message.error(this.$t('alter.input_phone_error'))
        const {newPhone,auth_code,oldPhone,old_auth_code}=this.changePhoneForm
        let params={}
        //已经绑定过手机号 userBoundPhone为true
        this.userBoundPhone ? params=[3,2,newPhone,auth_code,old_auth_code] : params=[3,2,newPhone,auth_code]
        var res=await bindPhoneOrEmail(...params)
        if (!res.success) return this.$message.error(res.message)
        const {data:userInfo}=await getUserInfo()
        localStorage.setItem('userInfo',JSON.stringify(userInfo))
        this.getUserInfo()
        if (this.userBoundPhone) this.$message.success(this.$t('alter.change_phone_success'))
        else this.$message.success(this.$t('alter.bind_phone_success'))
        this.changePhoneDialogVisible=false
      })
    },
    //  修改邮箱
    handleChangeEmail(){
      this.$refs.bindEmailFormRef.validate( async valid=>{
        if (!valid) return this.$message.error(this.$t('alter.input_email_error'))
        const {email,auth_code,oldEmail,old_auth_code}=this.bindEmailForm
        let params={}
        //已经绑定过邮箱 userBoundEmail为true
        this.userBoundEmail ? params=[3,1,email,auth_code,old_auth_code] : params=[3,1,email,auth_code]
        var res=await bindPhoneOrEmail(...params)
        if (!res.success) return this.$message.error(res.message)
        const {data:userInfo}=await getUserInfo()
        localStorage.setItem('userInfo',JSON.stringify(userInfo))
        this.getUserInfo()
        if (this.userBoundEmail) {
          // this.$message.success('更換郵箱成功 !')
          this.$message.success(this.$t('alter.change_email_success'))
          // localStorage.removeItem('token')
          // localStorage.removeItem('userInfo')
          // this.$router.replace('/login')
        }
        else {
          this.$message.success(this.$t('alter.bing_email_success'))
        }
        this.bindEmailDialogVisible=false
      })
    },
    handleClosePhoneDialog(){
      this.changePhoneForm = {
        oldPhone:'',
            old_auth_code:'',
            newPhone:'',
            auth_code:''
      }
      this.isOldPhoneSent = true
      this.isPhoneSent = true
    },
    handleCloseEmailDialog(){
      this.bindEmailForm.old_auth_code = ''
      this.bindEmailForm.email = ''
      this.bindEmailForm.auth_code = ''
      this.isOldEmailSent = true
      this.isEmailSent = true
    }
  }
}
</script>

<style scoped>
.el-form-item{
  position: relative;
}
.auth-code{
  position: absolute;
  top:40px;
  right: 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #8E9298;
}
.auth-code>div>span:first-child{
  font-size: 14px;
  font-weight: 400;
  color: #AB54DB;
  margin-right: 6px;
}
>>>.el-dialog__body {
  padding:0px 26px 15px !important;
}
>>>.el-dialog__header {
  padding-bottom: 0;
}
</style>
